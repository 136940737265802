import React from "react";
import { Helmet } from "react-helmet"
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Works from "../sections/landing1/Works";
import Contact from "../sections/landing1/Contact";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
           <meta charSet="utf-8" />
           <title>BigDog.zone - Marketplace of Athletic Skills</title>
           <link rel="canonical" href={"https://bigdog.zone/"} />
        </Helmet>
        <Hero />
        <Element name="works">
          <Works />
        </Element>

      </PageWrapper>
    </>
  );
};
export default IndexPage;
